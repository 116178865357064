<template style="background-color:#F6F7FF;">
   <v-app id="inspire" class="pt-0" style="background-color:#F6F7FF;">
      <v-main pt-0>
         <v-container fluid fill-height pt-0>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="info">
                        <v-toolbar-title>Iniciar sesion</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <form @submit.prevent="login">
                           <v-text-field
                             v-model="usuario.usr"
                              prepend-icon="person"
                              label="Usuario"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                             v-model="usuario.pwd"
                              id="password"
                              prepend-icon="lock"
                              label="Contraseña"
                              type="password"
                           ></v-text-field>

                             <v-spacer></v-spacer>
                        <v-btn color="indigo" type="submit" class="white--text">Login</v-btn>
                            <v-spacer></v-spacer>
                        </form>
                         <div v-if="mensaje !=''"><p>{{mensaje}}</p></div>
                     </v-card-text>
                     <v-card-actions>
                      
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>

import axios from 'axios';
import { mapMutations } from "vuex";

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data(){
      return{
           usuario:{usr:'', pwd:''},
           mensaje:''
      }
   }, methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),

          login(){
               console.log('clic:'+this.usuario);

               this.mostrarLoading({titulo:'accediendo'});
               let me=this;

                  axios.post('login', me.usuario)
                        .then(response=>{
                                    
                        
                                      console.log(response);

                                      const token=response.data.token;
                                      const dataUser=response.data.UsuarioDB;
                                      
                                      console.log(dataUser);

                                      if(dataUser && token){
                                         this.$store.dispatch("guardarToken", token);
                                      }

                                  

                                      me.ocultarLoading();
                                    

                                    }).catch(e=>{
                                          console.log(e)
                                          me.mensaje=e.response.data.mensaje;
                                          me.ocultarLoading();
                                    });

          }


   }, created(){

     // login();

      // this.$store.dispatch("autoLogin");

   }
};
</script>

<style></style>
